<template>
    <div ref="prev" style="width: 100%; height: 100%">
        <b-row ref="bgprev" id="bg-prev" :style="myStyles">
            <div style="width: 100%; height: 100%">
                <div v-show="editDbMode" :style="editStyle">
                    <b-icon class="dragIcon" icon="arrows-move" font-scale="2" style="position: absolute; top: 5px; left: 5px" />
                    <div>
                        <p>{{opt.title}}</p>
                        <b-button variant="secondary" size="sm" @click="modalEdit = !modalEdit">Edit chart</b-button>
                    </div>
                </div>

                <apexcharts height="100%" v-show="!editDbMode" class="chart" :options="chart.options" :series="chart.series" :key="componentKey"></apexcharts>
            </div>
            <b-modal title="Edit component" v-model="modalEdit" @ok="handleOk" @cancel="handleCancel">
                <b-form-group>
                    <b-container>
                        <b-row>
                            <b-col cols="5" class="pr-2 pl-0">
                                <b-row>
                                    <b-col class="d-flex flex-row" cols="12">
                                        <resource-filter class="mr-2" />
                                        <strong style="white-space: nowrap">Select storages</strong>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b-form-select size="sm" class="mt-1" v-model="storages" :options="storageOptions" multiple :select-size="10"></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="7" class="pl-2 pr-0">
                                <b-row>
                                    <b-col class="d-flex flex-row" cols="12">
                                        <strong>Chart options</strong>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker class="align-middle" id="titleColor" v-model="opt.titleColor" />
                                        <label size="sm" style="white-space: nowrap" for="title">Title:</label>
                                        <b-form-input class="w-50 ml-2" size="sm" id="title" type="text" v-model="opt.title"></b-form-input>
                                    </b-col>

                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="axisColors" v-model="opt.axisColors" />
                                        <label for="axisColors">Axis color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="gridColor" v-model="opt.gridColor" />
                                        <label for="gridColor">Grid color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="legendColor" v-model="opt.legendColor" />
                                        <label for="legendColor">Legend color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="xLabelColor" v-model="opt.xLabelColor" />
                                        <label for="xLabelColor">x-axis label color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="y1LabelColor" v-model="opt.y1LabelColor" />
                                        <label for="y1LabelColor">y-axis label color</label>
                                    </b-col>
                                </b-row>
                                <b-row class="mt-1">
                                    <b-col cols="12" class="d-inline-flex pr-0">
                                        <color-picker id="bkgColor" v-model="opt.bkgColor" />
                                        <label for="bkgColor">Background color</label>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <b-row class="mt-2">
                            <b-col class="px-0">
                                <strong>Configuration templates</strong>
                            </b-col>
                        </b-row>
                        <b-row class="mt-1">
                            <b-col cols="4" class="px-0">
                                <label for="newTemplateName">New template:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-input size="sm" id="newTemplateName" type="text" v-model="newTemplateName"></b-form-input>
                            </b-col>
                            <b-col cols="4" class="px-0">
                                <b-button variant="secondary" size="sm" @click="templateSave">Add</b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="4" class="px-0">
                                <label for="selectTemplate">Select template:</label>
                            </b-col>
                            <b-col cols="4">
                                <b-form-select size="sm" id="selectTemplate" type="text" :options="confTemplates" @change="templateChange" v-model="selectedTemplate" :select-size="1"></b-form-select>
                            </b-col>
                            <b-col cols="4" class="px-0">
                                <b-button variant="secondary" size="sm" @click="updateTemplate">Update</b-button>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
                <b-form-group>
                    <b-container>
                        <b-row>
                            <b-col cols="1" class="px-0">
                                <strong>col</strong>
                            </b-col>
                            <b-col cols="4">
                                <strong>type</strong>
                            </b-col>
                            <b-col cols="4">
                                <strong>size</strong>
                            </b-col>
                            <b-col cols="1" class="px-0">
                                <strong>y</strong>
                            </b-col>
                            <b-col cols="2" class="px-0">
                                <b-button class="float-right" variant="secondary" size="sm" @click="showSortModal = !showSortModal">
                                    Sort
                                </b-button>
                                <sortchartmodal :showSortModal.sync="showSortModal" :seriesConfArray="seriesConf" :storageNames="storageOptions" @reOrdered="reOrder"></sortchartmodal>
                            </b-col>
                        </b-row>

                        <b-row v-for="(storage, index) in seriesConf" :key="storage.storageId">
                            <b-col cols="12" align-self="center">
                                <b-row>
                                    <b-col cols="12" align-self="center" class="px-0">
                                        <div class="mt-1">{{ storageOptions.find(x => x.value === storage.storageId).text }}</div>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="1" align-self="center" class="px-0">
                                        <color-picker id="axisColors" v-model="seriesConf[index].color" />
                                    </b-col>
                                    <b-col cols="4" align-self="center">
                                        <b-form-select size="sm" id="selectGraphType" type="text" :options="graphTypeOptions" v-model="seriesConf[index].type" :select-size="1"></b-form-select>
                                    </b-col>
                                    <b-col cols="4" align-self="center">
                                        <b-form-input size="sm" id="selectGraphSze" type="number" placeholder="Line width" v-model="seriesConf[index].size" :select-size="1"></b-form-input>
                                    </b-col>
                                    <b-col cols="3" align-self="center" class="px-0">
                                        <b-form-select size="sm" id="selectYAxis" type="text" :select-size="1"></b-form-select>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
                <b-form-group>
                    <b-container>
                        <b-row>
                            <b-col cols="5" class="pl-0 pr-1">
                                <b-form-checkbox v-model="opt.stacked"
                                                 name="checkbox-stacked">
                                    Stacked chart
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="1" class="px-1"></b-col>
                            <b-col cols="3" class="px-1">
                                <b-form-checkbox v-model="opt.useymin"
                                                 name="checkbox-ymin">
                                    y-min
                                </b-form-checkbox>
                                <b-form-input v-show="opt.useymin" size="sm" id="ymin" type="number" v-model="opt.ymin"></b-form-input>
                            </b-col>
                            <b-col cols="3" class="pl-1 pr-0">
                                <b-form-checkbox v-model="opt.useymax"
                                                 name="checkbox-ymax">
                                    y-max
                                </b-form-checkbox>
                                <b-form-input v-show="opt.useymax" size="sm" id="ymax" type="number" v-model="opt.ymax"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="5" class="pl-0 pr-1">
                                <b-form-checkbox v-model="opt.raw"
                                                 name="checkbox-raw">
                                    raw data
                                </b-form-checkbox>
                            </b-col>
                            <b-col cols="1" class="px-1"></b-col>
                            <b-col cols="3" class="px-1">
                                
                            </b-col>
                            <b-col cols="3" class="pl-1 pr-0">
                                
                            </b-col>
                        </b-row>
                    </b-container>
                </b-form-group>
                <template #modal-footer="{ ok, cancel}">
                    <!-- Emulate built in modal footer ok and cancel button actions -->
                    <b-form-checkbox v-model="remove"
                                     name="checkbox-remove"
                                     value="yes"
                                     unchecked-value="no">
                        Remove this component
                    </b-form-checkbox>
                    <b-button variant="secondary" size="sm" @click="cancel()">
                        Cancel
                    </b-button>
                    <b-button variant="primary" size="sm" @click="ok()">
                        Ok
                    </b-button>
                </template>
            </b-modal>
        </b-row>
    </div>
</template>

<script>
    import VueApexCharts from 'vue-apexcharts'
    import { bus } from '@/main.js';
    import { axChart1Tmpl, isInSelectedCollectors, isInSelectedTypes } from '@/assets/l4a_jsAssets.js'
    import colorPicker from '@/components/Dashboard/colorPicker.vue';
    import sortChartModal from '@/components/Dashboard/sortChartModal.vue'
    import filter from '@/components/Settings/filter.vue';
    import { beautifySeries } from '../../assets/l4a_jsAssets';

    export default {
        name: 'largeChart',
        props: ['dbid', 'itemid', 'comp'],
        data() {
            return {
                x: 0,
                y: 0,
                modalEdit: false,
                selected: '',
                remove: 'no',
                opt: {},
                selectedStorages: [],
                seriesConf: [],
                yaxisOptions: ['y1', 'y2'],
                y1Unit: '',
                y2Unit: '',
                series: [],
                seriesColors: [],
                yAxis: {},
                stroke: {
                    curve: 'straight',
                    width: 1
                },
                fillType: [],
                graphTypeOptions: ['line', 'area'],
                updateArgs: [true, true],
                newTemplateName: '',
                selectedTemplate: '-',
                colors: "#eaeaea",
                showSortModal: false,
                chartType: 'area',
                opacy: [],
                chart: {
                    options: {},
                    series: []
                },
                previousPeriod: 24,
                componentKey: 0
            }
        },
        computed: {
            myStyles() {
                return {
                    'width': '100%',
                    'height': '100%',
                    'background-color': (this.editDbMode) ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0)',
                    'border-style': (this.editDbMode) ? 'solid' : 'none',
                    'border-width': '1px',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'center',
                    'margin-left': '0px'
                }
            },
            editStyle() {
                return {
                    'width': '100%',
                    'height': '100%',
                    'display': 'flex',
                    'align-items': 'center',
                    'justify-content': 'space-evenly'
                }
            },
            rfilter() {
                return this.$store.getters.filter
            },
            storageOptions() {
                let options = []
                let availableStorages = this.$store.getters.storages.filter(isInSelectedCollectors, {
                    selectedCollectors: this.rfilter.selectedCollectors,
                    changedCollectors: this.$store.getters.collectors,
                    changedSources: this.$store.getters.sources
                }).filter(isInSelectedTypes, {
                    selectedTypes: this.rfilter.selectedTypes,
                    changedSources: this.$store.getters.sources
                })

                for (let storage of this.seriesConf) {
                    options.push({ value: this.$store.getters.storages.find(x => x.placeId === storage.storageId).placeId, text: this.$store.getters.storages.find(x => x.placeId === storage.storageId).placeName })
                }

                for (let storage of availableStorages) {
                    if (options.findIndex(x => x.value === storage.placeId) === -1) {
                        options.push({ value: storage.placeId, text: storage.placeName })
                    }
                }
                return options.sort(function (a, b) {
                    var nameA = a.text.toLowerCase(), nameB = b.text.toLowerCase()
                    if (nameA < nameB) //sort string ascending
                        return -1
                    if (nameA > nameB)
                        return 1
                    return 0 //default return value (no sorting)
                })
            },
            confTemplates() {
                let options = []
                for (let template of this.$store.getters.confTemplates) {
                    if (template.type == this.comp.name) options.push({ value: template.name, text: template.name });
                }
                return options
            },
            storages: {
                get: function () {
                    if (this.selectedStorages.length == 0 && typeof this.comp.config.storages !== 'undefined') {
                        return this.comp.config.storages
                    } else {
                        return this.selectedStorages
                    }
                },
                set: function (newStorages) {
                    // Update seriesConf
                    let tempSeries = JSON.parse(JSON.stringify(this.seriesConf))
                    this.seriesConf = []
                    for (let storage of newStorages) {
                        let index = tempSeries.findIndex(x => x.storageId === storage)
                        if (typeof index === 'undefined' || index == -1) {
                            this.seriesConf.push({ storageId: storage, axis: 'y1', color: '#000000', type: 'area' })
                        } else {
                            this.seriesConf.push(tempSeries[index])
                        }
                    }
                    this.selectedStorages = newStorages

                }
            },
            editDbMode() {
                return this.$store.getters.editDbMode;
            }
        },
        created() {
            if (typeof this.comp.config.series !== 'undefined') {
                this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
            }
            if (typeof this.comp.config.storages !== 'undefined') {
                this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
            }
            this.chart.options = axChart1Tmpl
            if (typeof this.comp.config.opt !== 'undefined') {
                this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                if (this.comp.config.opt.axisColors !== undefined) this.chart.options.xaxis.axisBorder.color = JSON.parse(JSON.stringify(this.comp.config.opt.axisColors))
                if (this.comp.config.opt.bkgColor !== undefined) this.chart.options.chart.background = JSON.parse(JSON.stringify(this.comp.config.opt.bkgColor))
                if (this.comp.config.opt.gridColor !== undefined) this.chart.options.grid.borderColor = JSON.parse(JSON.stringify(this.comp.config.opt.gridColor))
                //if (this.comp.config.opt.titleColor !== undefined) this.chart.options.title.style.color = JSON.parse(JSON.stringify(this.comp.config.opt.titleColor))
                if (this.comp.config.opt.xLabelColor !== undefined) this.chart.options.xaxis.labels.style.colors = JSON.parse(JSON.stringify(this.comp.config.opt.xLabelColor))
                if (this.comp.config.opt.stacked !== undefined) this.chart.options.chart.stacked = JSON.parse(JSON.stringify(this.comp.config.opt.stacked))
                if (this.opt.axisColors !== undefined) this.opt.y1LabelColor = this.opt.axisColors
                if (this.opt.axisColors !== undefined) this.opt.y2LabelColor = this.opt.axisColors
            }
            this.chart = JSON.parse(JSON.stringify(this.chart))
            this.previousPeriod = this.$store.getters.timePeriod.period
        },
        mounted() {
            this.x = this.$refs.prev.clientWidth;
            this.y = this.$refs.prev.clientHeight;

            bus.$on('itemResized', (newSize) => {
                if (newSize.i == this.itemid) {
                    this.x = newSize.wpx
                    this.y = newSize.hpx
                }
            });
            bus.$on('newData', () => {
                let yAxis = {}
                if (this.$store.getters.data.length > 0 && typeof this.comp.config.storages !== 'undefined' && this.comp.config.storages.length > 0) {
                    let data = beautifySeries(JSON.parse(JSON.stringify(this.$store.getters.data)),this.opt.raw);
                    let chart = JSON.parse(JSON.stringify(this.chart))
                    chart.series = []
                    chart.options.colors = []
                    chart.options.stroke.width = []
                    chart.options.stroke.curve = []
                    chart.options.fill = {}
                    chart.options.yaxis = []
                    chart.options.title = {
                        text: this.opt.title,
                        align: 'left',
                        style: {
                            color: this.opt.titleColor
                        }
                    }
                    chart.options.subtitle = {
                        text: this.$store.getters.timePeriodStr,
                        align: 'left',
                        offsetY: 20,
                        style: {
                            color: this.opt.titleColor
                        }
                    }
                    chart.options.legend = {
                        labels: {
                            useSeriesColors: true
                        },
                        inverseOrder: false
                    }

                    for (let storage of this.selectedStorages) {
                        if (data.findIndex(x => x.placeId === storage) !== -1) {
                            let serieConfig = this.seriesConf.find(x => x.storageId === storage)
                            if (yAxis.labels === undefined) {
                                let y1Unit = ''
                                let metricId = this.$store.getters.storages.find(x => x.placeId === storage).metricId
                                if (metricId !== 'tbd') {
                                    y1Unit = this.$store.getters.metrics.find(x => x.id === metricId).unit
                                }
                                yAxis = {
                                    forceNiceScale: true,
                                    labels: {
                                        style: {
                                            colors: this.opt.y1LabelColor
                                        },
                                        formatter: function (val) {
                                            return Math.round(val * 100) / 100 + ' ' + y1Unit;
                                        }
                                    },
                                    axisTicks: {
                                        show: true,
                                        color: this.opt.y1LabelColor
                                    },
                                    axisBorder: {
                                        show: true,
                                        color: this.opt.y1LabelColor
                                    }
                                };
                                if (this.opt.useymin) yAxis.min = parseInt(this.opt.ymin)
                                if (this.opt.useymax) yAxis.min = parseInt(this.opt.ymax)

                            }

                            //console.log(data.find(x => x.placeId === storage))
                            if (this.$store.getters.timePeriod.period > 168) {
                                if (data.find(x => x.placeId === storage).hcDataRange.length > 0) {
                                    chart.options.chart.type = 'rangeArea'
                                    chart.options.colors.push(serieConfig.color)
                                    chart.options.stroke.width.push(0)
                                    chart.options.stroke.curve.push((data.find(x => x.placeId === storage).hcData.length < 400) ? 'smooth' : 'straight')
                                    if (chart.options.fill.opacy === undefined) chart.options.fill.opacy = []
                                    chart.options.fill.opacy.push(0.3)
                                    //if (chart.options.legend.customLegendItems === undefined) chart.options.legend.customLegendItems = []
                                    //chart.options.legend.customLegendItems.push(this.$store.getters.storages.find(x => x.placeId === storage).placeName)
                                    //if (chart.options.legend.labels.colors === undefined) chart.options.legend.labels.colors = []
                                    //chart.options.legend.labels.colors.push(serieConfig.color)
                                    //chart.options.legend.labels.useSeriesColors = false
                                    //let dataRange = [];
                                    //for (let sample of data.find(x => x.placeId === storage).hcDataRange) {
                                    //    dataRange.push({
                                    //        x: sample[0],
                                    //        y: [sample[1], sample[2]]
                                    //    })
                                    //}
                                    chart.series.push({
                                        name: this.$store.getters.storages.find(x => x.placeId === storage).placeName + " range",
                                        data: data.find(x => x.placeId === storage).hcDataRange,
                                        type: 'rangeArea'
                                    })
                                }
                                chart.options.chart.type = 'rangeArea'
                                chart.options.colors.push(serieConfig.color)
                                chart.options.stroke.width.push(1)
                                chart.options.stroke.curve.push((data.find(x => x.placeId === storage).hcData.length < 400) ? 'smooth' : 'straight')
                                if (chart.options.fill.opacy === undefined) chart.options.fill.opacy = []
                                chart.options.fill.opacy.push(1)
                                //let data = [];
                                //for (let sample of data.find(x => x.placeId === storage).hcData) {
                                //    data.push({
                                //        x: sample[0],
                                //        y: sample[1]
                                //    })
                                //}
                                chart.series.push({
                                    name: this.$store.getters.storages.find(x => x.placeId === storage).placeName,
                                    data: data.find(x => x.placeId === storage).hcData,
                                    type: 'line'
                                })
                            } else {
                                chart.options.chart.type = 'area'
                                chart.options.colors.push(serieConfig.color)
                                if (typeof serieConfig.size === 'undefined') { chart.options.stroke.width.push(1) } else { chart.options.stroke.width.push(serieConfig.size) }
                                chart.options.stroke.curve.push((data.find(x => x.placeId === storage).hcData.length < 400) ? 'smooth' : 'straight')
                                if (chart.options.fill.type === undefined) chart.options.fill.type = []
                                if (chart.options.fill.gradient === undefined) chart.options.fill.gradient = {
                                    type: 'vertical',
                                    shadeIntensity: 1,
                                    inverseColors: false,
                                    opacityFrom: 0.3,
                                    opacityTo: 0,
                                    stops: [0, 100]
                                }
                                if (serieConfig.type === undefined || serieConfig.type == 'area') { chart.options.fill.type.push('gradient') } else { chart.options.fill.type.push('solid') }
                                chart.series.push({
                                    name: this.$store.getters.storages.find(x => x.placeId === storage).placeName,
                                    data: data.find(x => x.placeId === storage).hcData,
                                    type: serieConfig.type != undefined ? serieConfig.type : 'area'
                                })
                            }
                        }
                    }
                    //var _thisChart = this.chart
                    //console.log(_thisChart)
                    chart.options.yaxis.push(yAxis)
                    console.log(chart)
                    this.chart = JSON.parse(JSON.stringify(chart))
                    this.chart.options.yaxis[0] = yAxis
                    //console.log(this.chart)

                    // Workaround for Apexcharts issue when switching from type rangeArea to area or line
                    if (this.previousPeriod > 168 && this.$store.getters.timePeriod.period <= 168 || this.previousPeriod <= 168 && this.$store.getters.timePeriod.period > 168) {
                        this.$nextTick(() => {
                            this.forceRerender()
                        });
                    }
                    this.previousPeriod = this.$store.getters.timePeriod.period
                }
            });
        },
        methods: {
            forceRerender() {
                //this.$forceUpdate();
                this.componentKey += 1;
            },
            handleOk() {
                let payload = {
                    dbid: this.dbid,
                    itemid: this.itemid,
                    newConfig: {
                        storages: this.selectedStorages,
                        opt: this.opt,
                        series: this.seriesConf
                    }
                }
                this.$store.commit('updateCompConfig', payload);
                // add additional component
                if (this.selected != '') {
                    let compIndex = this.$store.getters.dbComponents.findIndex(x => x.name === this.selected);
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid,
                        newComp: this.$store.getters.dbComponents[compIndex]
                    }
                    this.selected = '';
                    this.$store.commit('addComp', payload);
                } else if (this.remove == 'yes') {
                    let payload = {
                        dbid: this.dbid,
                        itemid: this.itemid
                    }
                    this.remove = 'no'
                    this.$emit('removed', payload)
                }
                this.$store.commit('updateStorageIds', this.dbid)
            },
            handleCancel() {
                if (typeof this.comp.config.series !== 'undefined') {
                    this.seriesConf = JSON.parse(JSON.stringify(this.comp.config.series))
                }
                if (typeof this.comp.config.storages !== 'undefined') {
                    this.selectedStorages = JSON.parse(JSON.stringify(this.comp.config.storages))
                }
                if (typeof this.comp.config.opt !== 'undefined') {
                    this.opt = JSON.parse(JSON.stringify(this.comp.config.opt))
                }
            },
            templateChange() {
                let tmpl = JSON.parse(JSON.stringify(this.$store.getters.confTemplates.find(x => x.name === this.selectedTemplate).tmpl))
                tmpl.opt.title = this.opt.title
                this.opt = tmpl.opt
            },
            templateSave() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.newTemplateName,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                this.$store.commit('addConfTemplate', payload)
                this.selectedTemplate = this.newTemplateName
                this.newTemplateName = ''
            },
            updateTemplate() {
                let opt = JSON.parse(JSON.stringify(this.opt))
                opt.title = 'tbd'
                let payload = {
                    name: this.selectedTemplate,
                    type: this.comp.name,
                    tmpl: {
                        opt: opt
                    }
                }
                console.log(payload)
                this.$store.commit('updateConfTemplate', payload)
            },
            reOrder(value) {
                console.log(value)
                let movedColor = this.seriesColors.splice(value.oldIndex, 1)
                this.seriesColors.splice(value.newIndex, 0, movedColor[0])
                let movedSeries = this.series.splice(value.oldIndex, 1)
                this.series.splice(value.newIndex, 0, movedSeries[0])
                let movedFillType = this.fillType.splice(value.oldIndex, 1)
                this.fillType.splice(value.newIndex, 0, movedFillType[0])
                let thisStorage = this.selectedStorages.splice(value.oldIndex, 1)
                this.selectedStorages.splice(value.newIndex, 0, thisStorage[0])
            }
        },
        components: {
            apexcharts: VueApexCharts,
            'color-picker': colorPicker,
            'resource-filter': filter,
            'sortchartmodal': sortChartModal
        },
        watch: {
            opt(newVal, oldVal) {
                if (newVal.raw != oldVal.raw) bus.$emit('newData')
            }
        }
    };
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>